<template>
    <div class="c-main">
        <!--图片区-->
        <div class="c-pic">
            <el-image
                :src="info.img"
                style="height: 100%;width: 100%"
                fit="contain"
            ></el-image>
        </div>
        <!--tab区-->
        <div class="c-tab">
            <el-row>
                <el-col :span="12">
                    <div class="c-tab-text" v-if="info.type==1" style="background-color:#e50525 ">补充包</div>
                    <div class="c-tab-text" v-if="info.type==2" style="background-color:#1c8531 ">预组</div>
                    <div class="c-tab-text" v-if="info.type==3" style="background-color:#4775b3 ">其他</div>
                </el-col>
            </el-row>
        </div>
        <!--title-->
        <div class="c-title text-padding">
            <h5>{{"["+info.no+"]"+info.name}}</h5>
        </div>
        <!--发布日期-->
        <div class="c-first-date">
            <span class="c-first-date-text">&nbsp;{{info.sale}}&nbsp;</span>
        </div>
        <!--国家-->
        <div class="text-padding">
            <h6 v-if="info.select_n_g == 'nation'">收录国家:{{info.nation}}</h6>
            <h6 v-if="info.select_n_g == 'group'">收录集团:{{info.group}}</h6>
        </div>
        <!--建议零售价-->
        <div class="text-padding">
            <h6 v-html="info.steer_sale"></h6>
        </div>
    </div>
</template>

<script>
    export default {
        name: "product-item",
        props: {
            info: {
                type: Object,
                default: function () {
                    return {};
                }
            },
        },
    }
</script>

<style scoped>
    .c-main{
        background-color: white;
        height: 410px;
    }

    .c-main > div{
        vertical-align:top
    }

    .c-main:hover{
        cursor: pointer;
        opacity: 0.7;
    }
    .c-pic{
        height: 110px;
    }
    .c-tab{
        height: 30px;
        line-height: 30px;
        margin-top: 20px;
    }
    .c-tab-text{
        color: white;
        font-weight: bold;
    }
    .c-first-date-text{
        border: 1px solid #111111;
        font-size: 14px;
    }
    .text-padding{
        padding: 0 12px;
    }
    .c-title{
        height: 70px;
    }
</style>
